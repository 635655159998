/* eslint-disable no-console */
import React from "react"
import axios from "axios"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { FormattedMessage, injectIntl, IntlShape } from "react-intl"
import {
  Button,
  ButtonDropdown,
  Container,
  Header,
  Link,
  Popover,
  StatusIndicator,
  SpaceBetween,
  TextContent,
  Icon,
  Box,
  Grid,
  Alert,
  FormField,
  Spinner,
  ButtonDropdownProps
} from "@amzn/awsui-components-react/polaris"
import { Pie } from "react-chartjs-2"
import JsPdf from "jspdf"
import { API, graphqlOperation } from "aws-amplify"
import messages from "./SummaryReport.messages"
import common from "../Common.messages"
import "chart.js/dist/Chart.css"
import "./SummaryReport.css"
import Constants from "../../Constants"
import FeatureAccessControl from "../../lib/auth/FeatureAccessControl"
import {
  getEngagementSummaryReport,
  getEngagementDetailedExport
} from "./graphql/queries"
import AppConfig from "../../Config"

const { Config } = AppConfig
const migrationEvaluatorLogo = require("./migration_evaluator.PNG")?.default

interface ISummaryReportDetails {
  getEngagementSummaryReport: {
    Id: string
    EngagementName: string
    CompanyName: string
    CreatedOn: string
    AnalysisStartDate: string
    AnalysisEndDate: string
    HasEnoughUtilization: boolean
    Data: ISummaryReportData
    Scenario: IScenarioDefinition
  }
}

interface ISummaryReportData {
  DirectMatchResult: IDirectMatchResult
  RightSizedMatchResult: IRightSizedMatchResult
  CountByServerType: ICountByServerType
  CountByDatabase: ICountByDatabase
  CountByOperatingSystem: ICountByOperatingSystem
  StorageDetails: IStorageDetails
  Utilization: IUtilization
}

interface IDirectMatchResult {
  ComputeAnnualCost: number
  StorageAnnualCost: number
  Ec2Cost: number
  EbsCost: number
  TotalLicensesCost: number
  OSLicensesCost: number
  SoftwareLicensesCost: number
}

interface IRightSizedMatchResult {
  ComputeAnnualCost: number
  StorageAnnualCost: number
  SavingsPercent: number
  Ec2Cost: number
  EbsCost: number
  TotalLicensesCost: number
  OSLicensesCost: number
  SoftwareLicensesCost: number
}

interface ICountByServerType {
  VirtualMachines: number
  PhysicalServers: number
}

interface ICountByDatabase {
  SqlStandard: number
  SqlEnterprise: number
  SqlWeb: number
  SqlDeveloper: number
  SqlExpress: number
  Other: number
}

interface ICountByOperatingSystem {
  Windows: number
  RHEL: number
  SUSE: number
  Linux: number
  Other: number
}
interface IStorageDetails {
  AttachedBlockStorage: number
}

interface IUtilization {
  PeakCpuUtilizationPercent: number
  PeakMemoryUtilizationPercent: number
}

interface IScenarioDefinition {
  HasSoftwareAssurance: boolean
  PurchaseType: string
  Region: string
}

interface IReportVariations {
  IsTemporal: boolean
  HasUtilization: boolean
}

interface IDetailedReportDetails {
  getEngagementDetailedExport: {
    ReportId: string
    CreatedOn: string
    Data: IDetailedReportData
  }
}

interface IDetailedReportData {
  S3SignedUrl: string
}

interface ISummaryReportProps {
  intl: IntlShape
  engagementId: string
  summaryReportId: string
  detailedReportId: string
  availableDetailedFormats: string[]
  hasReport: boolean
}

interface ISummaryReportState {
  loading: boolean
  isDownloadingDetailedReport: boolean
  errorExist: boolean
  reportDetails: ISummaryReportDetails
  detailedReportDetails: IDetailedReportDetails
  canDownloadPhysicalServerReport: boolean
}

enum DetailedFormat {
  Standard = "standard",
  MPA = "mpa",
  PHYSICAL = "physical"
}

const AmazonFontLight = "AmazonFontLight"
const AmazonFontBold = "AmazonFontBold"
const AmazonFontLightItalic = "AmazonFontLightItalic"

const StandardDetailedFormatId = "standardDetailedFormat"
const MpaDetailedFormatId = "mpaDetailedFormat"
const PhysicalDetailedFormatId = "physicalDetailedFormat"
const maxEngagementNameLength = 128
const engagementNameRegex = /[^a-zA-Z0-9-._+]/g
const dateTimeRegex = /[T:]/g

export class SummaryReport extends React.Component<
  RouteComponentProps & ISummaryReportProps,
  ISummaryReportState
> {
  private static formatUTC(reportDetails: string) {
    return new Date(reportDetails).toLocaleString(undefined, {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })
  }

  private formatMessage: Function

  private mounted: boolean

  private reportVariation: IReportVariations

  constructor(props: RouteComponentProps & ISummaryReportProps) {
    super(props)
    const {
      intl: { formatMessage }
    } = this.props
    this.formatMessage = formatMessage

    this.state = {
      loading: true,
      isDownloadingDetailedReport: false,
      errorExist: false,
      reportDetails: {} as ISummaryReportDetails,
      detailedReportDetails: {} as IDetailedReportDetails,
      canDownloadPhysicalServerReport: false
    }
    this.reportVariation = {
      IsTemporal: false,
      HasUtilization: true
    }
    this.mounted = false
  }

  async componentDidMount() {
    this.mounted = true
    await this.getReportData()
    await this.getFeatureStatus()
  }

  async componentDidUpdate(
    prevProps: RouteComponentProps & ISummaryReportProps
  ) {
    const { engagementId } = this.props
    if (engagementId !== prevProps.engagementId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: true
      })
      await this.getReportData()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getFeatureStatus = async () => {
    if (this.mounted) {
      this.setState({
        canDownloadPhysicalServerReport: await FeatureAccessControl.isFeatureOn(
          Constants.Features.CanDownloadPhysicalServerReport
        )
      })
    }
  }

  getReportData = async () => {
    const { engagementId, summaryReportId } = this.props
    try {
      const reportResponse = await API.graphql(
        graphqlOperation(getEngagementSummaryReport, {
          engagementId,
          reportId: summaryReportId
        })
      )
      const summaryReportDetail = reportResponse as {
        data: ISummaryReportDetails
      }

      this.updateReportDataStateAndValidate(summaryReportDetail.data)
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({
        errorExist: true
      })
    }
  }

  formatDetailedReportFileName = (
    format: string,
    engagementName: string,
    reportCreatedOn: string,
    fileExtension: string = "zip"
  ): string => {
    let formattedReportFileName: string = ""

    const formattedEngagementName = this.formatFileNameEngagementName(
      engagementName
    )
    const formattedDatetime = this.formatFileNameReportDateTime(reportCreatedOn)
    formattedReportFileName = `${format}-${formattedEngagementName}_${formattedDatetime}.${fileExtension}`
    return formattedReportFileName
  }

  private formatFileNameReportDateTime = (reportCreatedOn: string): string => {
    const reportCreatedOnDateTime = new Date(reportCreatedOn)
    const reportCreatedOnDateTimeISOString = reportCreatedOnDateTime.toISOString()
    const formatedDateTime = reportCreatedOnDateTimeISOString
      .replace(dateTimeRegex, "-")
      .split(".")[0]
    return formatedDateTime
  }

  private sanitizeEngagementName = (engagementName: string): string => {
    const sanitizedEngagementName = String(engagementName)
      .replace(engagementNameRegex, "")
      .toLowerCase()
    return sanitizedEngagementName
  }

  private formatFileNameEngagementName = (engagementName: string): string => {
    let formattedEngagementName = this.sanitizeEngagementName(engagementName)
    if (formattedEngagementName.length > maxEngagementNameLength) {
      formattedEngagementName = formattedEngagementName.slice(
        0,
        maxEngagementNameLength
      )
    }
    return formattedEngagementName
  }

  private downloadDetailedExport = async (format: string = "") => {
    const { engagementId, detailedReportId } = this.props
    if (!detailedReportId) {
      return
    }

    this.setState({
      isDownloadingDetailedReport: true
    })

    try {
      const detailedReportData = await this.getDetailedReportData(
        engagementId,
        detailedReportId,
        format
      )

      const presignedS3Url =
        detailedReportData.getEngagementDetailedExport.Data.S3SignedUrl

      const { reportDetails } = this.state
      const engagementName =
        reportDetails.getEngagementSummaryReport.EngagementName
      const reportCreatedOn =
        detailedReportData.getEngagementDetailedExport.CreatedOn
      const formattedFileName = this.formatDetailedReportFileName(
        format,
        engagementName,
        reportCreatedOn
      )
      axios.get(presignedS3Url, { responseType: "blob" }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.setAttribute("id", "detailedReportDownloadLink")
        document.body.appendChild(link)
        link.href = url
        link.setAttribute("download", formattedFileName)
        link.target = "_blank"
        link.click()
        document.body.removeChild(link)
      })
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({
        errorExist: true
      })
    }

    this.setState({
      isDownloadingDetailedReport: false
    })
  }

  getDetailedReportData = async (
    engagementId: string,
    detailedReportId: string,
    format: string
  ): Promise<IDetailedReportDetails> => {
    try {
      const reportResponse = await API.graphql(
        graphqlOperation(getEngagementDetailedExport, {
          engagementId,
          reportId: detailedReportId,
          format
        })
      )

      const detailedReportDetails = reportResponse as {
        data: IDetailedReportDetails
      }

      this.validateDetailedReportData(detailedReportDetails.data)

      return detailedReportDetails.data
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({
        errorExist: true
      })

      return Promise.reject()
    }
  }

  checkReportVariations = () => {
    const { reportDetails } = this.state
    if (reportDetails.getEngagementSummaryReport !== null) {
      this.reportVariation.HasUtilization =
        reportDetails.getEngagementSummaryReport.HasEnoughUtilization
      if (
        reportDetails.getEngagementSummaryReport.AnalysisStartDate !== null &&
        reportDetails.getEngagementSummaryReport.AnalysisEndDate !== null
      ) {
        this.reportVariation.IsTemporal = true
      } else {
        this.reportVariation.IsTemporal = false
      }
    }
  }

  /* istanbul ignore next */
  downloadPdf = async () => {
    const { reportDetails } = this.state

    const pdf = new JsPdf("p", "mm", "a4")
    const width = 210
    const leftMargin = 15
    const largeMargin = 15
    const smallMargin = 6
    const tinyMargin = 3
    const firstParagraphMargin = 18
    const secondParagraphMargin = 39
    const thirdParagraphMargin = 8
    const titleSize = 20
    const subTitleSize = 10
    const heading2Size = 14
    const bodySize = 11
    const footerSize = 8
    const splitTextFullWidth = 240
    const footerWidth = 350
    const lineSpacingInMm = 5
    const lineSpacingFactor = 1.4
    const leftColumnSpacingFactor = 1.7
    const pieChartImageHeight = 60

    const aboutReportParagraphMargin = this.reportVariation.IsTemporal ? 6 : 0

    const amazonOrange = "#ff9900"
    const grey600 = "#545b64"
    const grey900 = "#2a2e33"

    const grey600Rgb = this.hexToRgb(grey600)
    const amazonOrangeRgb = this.hexToRgb(amazonOrange)
    const grey900Rgb = this.hexToRgb(grey900)

    const logoImage = document.getElementById(
      "migrationEvaluatorLogo"
    ) as HTMLImageElement
    const logoImageHeight = 40

    let pieChartImageData

    if (this.reportVariation.HasUtilization) {
      const pieChartDiv = document.getElementById("pieChart")
      if (!pieChartDiv) {
        console.error("Could not get barChart")
        return
      }

      const pieChart = pieChartDiv.querySelector("canvas") as HTMLCanvasElement
      if (!pieChart) {
        console.error("Could not get pieChart")
        return
      }

      pieChartImageData = pieChart.toDataURL("image/png")
    }

    const workloadCostSavings = document.getElementById("workloadCostSavings")
    if (!workloadCostSavings) {
      console.error("Could not get workloadCostSavings")
      return
    }

    const workloadCostSavingsLines = pdf.splitTextToSize(
      workloadCostSavings.innerText,
      splitTextFullWidth + 25
    )

    const costSavingAndAwsBenefits = document.getElementById(
      "costSavingAndAwsBenefits"
    )
    if (!costSavingAndAwsBenefits) {
      console.error("Could not get costSavingAndAwsBenefits")
      return
    }

    const costSavingAndAwsBenefitsLines = pdf.splitTextToSize(
      costSavingAndAwsBenefits.innerText,
      this.reportVariation.HasUtilization
        ? splitTextFullWidth * (4 / 7)
        : splitTextFullWidth + 25
    )

    let costReductionAnalysisLines
    if (Config.Features.EnableOsCostForSummaryReport) {
      const costReductionAnalysis = document.getElementById(
        "costReductionAnalysis"
      )
      if (!costReductionAnalysis) {
        console.error("Could not get costReductionAnalysis")
        return
      }

      costReductionAnalysisLines = pdf.splitTextToSize(
        costReductionAnalysis.innerText,
        splitTextFullWidth * (4 / 7)
      )
    }
    const nextSteps = document.getElementById("nextSteps")
    if (!nextSteps) {
      console.error("Could not get nextSteps")
      return
    }

    const nextStepsLines = pdf.splitTextToSize(
      nextSteps.innerText,
      this.reportVariation.HasUtilization
        ? splitTextFullWidth * (4 / 7)
        : splitTextFullWidth + 25
    )

    const analysisTimePeriod = document.getElementById("analysisTimeFrame")
    if (!analysisTimePeriod) {
      console.error("Could not get analysisTimePeriod")
      return
    }

    const analysisTimePeriodLines = pdf.splitTextToSize(
      analysisTimePeriod.innerText,
      splitTextFullWidth
    )

    const servers = document.getElementById("servers")
    if (!servers) {
      console.error("Could not get servers")
      return
    }

    const virtualMachines = document.getElementById("virtualMachines")
    if (!virtualMachines) {
      console.error("Could not get virtualMachines")
      return
    }

    const physicalServers = document.getElementById("physicalServers")
    if (!physicalServers) {
      console.error("Could not get physicalServers")
      return
    }

    const licensing = document.getElementById("licensing")
    if (!licensing) {
      console.error("Could not get licensing")
      return
    }

    const operatingSystemServers = document.getElementById(
      "operatingSystemServers"
    )
    if (!operatingSystemServers) {
      console.error("Could not get operatingSystemServers")
      return
    }

    const operatingSystemServersLines = pdf.splitTextToSize(
      `- ${operatingSystemServers.innerText}`,
      splitTextFullWidth / leftColumnSpacingFactor
    )

    const sqlServers = document.getElementById("sqlServers")
    if (!sqlServers) {
      console.error("Could not get sqlServers")
      return
    }

    const sqlServersLines = pdf.splitTextToSize(
      `- ${sqlServers.innerText}`,
      splitTextFullWidth / leftColumnSpacingFactor
    )

    const storage = document.getElementById("storage")
    if (!storage) {
      console.error("Could not get storage")
      return
    }

    const attachedBlockStorage = document.getElementById("attachedBlockStorage")
    if (!attachedBlockStorage) {
      console.error("Could not get attachedBlockStorage")
      return
    }

    const utilization = document.getElementById("utilization")
    if (!utilization) {
      console.error("Could not get utilization")
      return
    }

    const peakCpuUtilization = document.getElementById("peakCpuUtilization")
    if (!peakCpuUtilization) {
      console.error("Could not get peakCpuUtilization")
      return
    }

    const peakMemoryUtilization = document.getElementById(
      "peakMemoryUtilization"
    )
    if (!peakMemoryUtilization) {
      console.error("Could not get peakMemoryUtilization")
      return
    }

    const projectedCostsFooter = document.getElementById("projectedCostsFooter")
    if (!projectedCostsFooter) {
      console.error("Could not get projectedCostsFooter")
      return
    }

    const projectCollectorHintFooter = document.getElementById(
      "projectCollectorHintFooter"
    )
    if (!projectCollectorHintFooter) {
      console.error("Could not get projectCollectorHintFooter")
      return
    }

    const utilizationHintFooter = document.getElementById(
      "utilizationHintFooter"
    )
    if (!utilizationHintFooter) {
      console.error("Could not get utilizationHintFooter")
      return
    }

    const engagementNameFooter = document.getElementById("engagementNameFooter")
    if (!engagementNameFooter) {
      console.error("Could not get engagementNameFooter")
      return
    }

    const copyRightFooter = document.getElementById("copyRightFooter")
    if (!copyRightFooter) {
      console.error("Could not get copyRightFooter")
      return
    }

    const projectedCostsFooterLines = pdf.splitTextToSize(
      projectedCostsFooter.innerText,
      footerWidth
    )
    const projectCollectorHintFooterLines = pdf.splitTextToSize(
      projectCollectorHintFooter.innerText,
      footerWidth
    )
    const utilizationHintFooterLines = pdf.splitTextToSize(
      utilizationHintFooter.innerText,
      footerWidth
    )
    const engagementNameFooterLines = pdf.splitTextToSize(
      engagementNameFooter.innerText,
      footerWidth
    )

    const copyRightFooterLines = pdf.splitTextToSize(
      copyRightFooter.innerText,
      footerWidth
    )

    pdf.addFileToVFS(
      "AmazonEmber/Amazon_Ember_Lt.ttf",
      Constants.AmazonEmberFontBase64String.Light
    )

    pdf.addFileToVFS(
      "AmazonEmber/Amazon_Ember_Bd.ttf",
      Constants.AmazonEmberFontBase64String.Bold
    )

    pdf.addFileToVFS(
      "AmazonEmber/Amazon_Ember_LtIt.ttf",
      Constants.AmazonEmberFontBase64String.LightItalic
    )

    pdf.addFont("AmazonEmber/Amazon_Ember_Lt.ttf", AmazonFontLight, "Light")
    pdf.addFont("AmazonEmber/Amazon_Ember_Bd.ttf", AmazonFontBold, "Bold")
    pdf.addFont(
      "AmazonEmber/Amazon_Ember_LtIt.ttf",
      AmazonFontLightItalic,
      "Italic"
    )

    pdf
      .addImage(logoImage, "JPEG", 0, 0, width, logoImageHeight)
      .setFontSize(titleSize)
      .setTextColor(grey600Rgb.r, grey600Rgb.g, grey600Rgb.b)
      .setFont("AmazonFontBold", "Bold")
      .text(
        this.formatMessage(messages.reportTitle),
        leftMargin,
        logoImageHeight + largeMargin
      )
      .setFontSize(subTitleSize)
      .setFont("AmazonFontLight", "Light")
      .text(
        this.formatMessage(messages.dateSubtitle, {
          date: SummaryReport.formatUTC(
            reportDetails.getEngagementSummaryReport.CreatedOn
          )
        }),
        leftMargin,
        logoImageHeight + largeMargin + smallMargin
      )
      .setFontSize(bodySize)
      .setTextColor(grey900Rgb.r, grey900Rgb.g, grey900Rgb.b)
    if (this.reportVariation.HasUtilization) {
      this.printNormalBoldMix(
        pdf,
        workloadCostSavingsLines,
        leftMargin,
        logoImageHeight + largeMargin + smallMargin * 3,
        lineSpacingInMm
      )
    }

    if (this.reportVariation.HasUtilization) {
      this.printNormalBoldMix(
        pdf,
        costSavingAndAwsBenefitsLines,
        leftMargin,
        logoImageHeight +
          largeMargin +
          smallMargin +
          firstParagraphMargin +
          tinyMargin * 2,
        lineSpacingInMm
      )
      if (Config.Features.EnableOsCostForSummaryReport) {
        this.printNormalBoldMix(
          pdf,
          costReductionAnalysisLines,
          leftMargin,
          logoImageHeight +
            largeMargin +
            smallMargin * 2 +
            firstParagraphMargin +
            secondParagraphMargin +
            tinyMargin,
          lineSpacingInMm
        )
      }
      this.printNormalBoldMix(
        pdf,
        nextStepsLines,
        leftMargin,
        logoImageHeight +
          largeMargin +
          smallMargin * (Config.Features.EnableOsCostForSummaryReport ? 5 : 2) +
          firstParagraphMargin +
          secondParagraphMargin +
          tinyMargin,
        lineSpacingInMm
      )
    } else {
      this.printNormalBoldMix(
        pdf,
        costSavingAndAwsBenefitsLines,
        leftMargin,
        logoImageHeight + largeMargin + smallMargin * 3,
        lineSpacingInMm
      )
      this.printNormalBoldMix(
        pdf,
        nextStepsLines,
        leftMargin,
        logoImageHeight +
          largeMargin +
          smallMargin * 4 +
          (firstParagraphMargin * 2) / 3,
        lineSpacingInMm
      )
    }
    if (this.reportVariation.HasUtilization) {
      pdf.addImage(
        pieChartImageData || "",
        "JPEG",
        splitTextFullWidth / 2 - smallMargin,
        logoImageHeight + largeMargin + smallMargin * 2 + firstParagraphMargin,
        (pieChartImageHeight * 4) / 3,
        pieChartImageHeight
      )
    }
    pdf
      .setFontSize(heading2Size)
      .setFont("AmazonFontBold", "Bold")
      .setTextColor(amazonOrangeRgb.r, amazonOrangeRgb.g, amazonOrangeRgb.b)
      .text(
        this.formatMessage(messages.aboutReportTitle),
        leftMargin,
        logoImageHeight +
          smallMargin * 4 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin * 3
            : 0) +
          pieChartImageHeight
      )
      .setFontSize(bodySize)
      .setFont("AmazonFontLight", "Light")
      .setTextColor("black")
      .setLineHeightFactor(lineSpacingFactor)
      .text(
        analysisTimePeriodLines,
        leftMargin,
        logoImageHeight +
          smallMargin * 5 +
          tinyMargin +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin * 3
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontBold", "Bold")
      .text(
        servers.innerText,
        leftMargin,
        logoImageHeight +
          largeMargin +
          smallMargin * 5 +
          tinyMargin +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontLight", "Light")
      .text(
        `- ${virtualMachines.textContent}`,
        leftMargin,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 6 +
          tinyMargin +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .text(
        `- ${physicalServers.innerText}`,
        leftMargin,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 7 +
          tinyMargin +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontBold", "Bold")
      .text(
        licensing.innerText,
        leftMargin,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 8 +
          tinyMargin * 2 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontLight", "Light")
      .text(
        operatingSystemServersLines,
        leftMargin,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 9 +
          tinyMargin * 2 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .text(
        sqlServersLines,
        leftMargin,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 10 +
          tinyMargin * 2 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight +
          (operatingSystemServersLines.length > 1
            ? lineSpacingInMm * (operatingSystemServersLines.length - 1)
            : 0)
      )
      .setFont("AmazonFontBold", "Bold")
      .text(
        storage.innerText,
        splitTextFullWidth / 2,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 5 +
          tinyMargin +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontLight", "Light")
      .text(
        `- ${attachedBlockStorage.innerText}`,
        splitTextFullWidth / 2,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 6 +
          tinyMargin +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontBold", "Bold")
      .text(
        utilization.innerText,
        splitTextFullWidth / 2,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 6 +
          tinyMargin * 4 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontLight", "Light")
      .text(
        `- ${peakCpuUtilization.innerText}`,
        splitTextFullWidth / 2,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 8 +
          tinyMargin * 2 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .text(
        `- ${peakMemoryUtilization.innerText}`,
        splitTextFullWidth / 2,
        logoImageHeight +
          largeMargin * 1 +
          smallMargin * 9 +
          tinyMargin * 2 +
          (this.reportVariation.HasUtilization
            ? largeMargin +
              firstParagraphMargin +
              thirdParagraphMargin +
              tinyMargin +
              aboutReportParagraphMargin
            : 0) +
          pieChartImageHeight
      )
      .setFont("AmazonFontLightItalic", "Italic")
      .setFontSize(footerSize)
      .text(
        projectedCostsFooterLines,
        leftMargin + 84,
        logoImageHeight +
          largeMargin * 3 +
          smallMargin * 12 +
          tinyMargin * 5 +
          firstParagraphMargin +
          pieChartImageHeight +
          (sqlServersLines.length > 2 ? tinyMargin : 0),
        { align: "center" }
      )
      .text(
        projectCollectorHintFooterLines,
        leftMargin + 84,
        logoImageHeight +
          largeMargin * 3 +
          smallMargin * 15 +
          tinyMargin * 6 +
          firstParagraphMargin +
          pieChartImageHeight +
          (sqlServersLines.length > 2 ? tinyMargin : 0),
        { align: "center" }
      )
      .text(
        utilizationHintFooterLines,
        leftMargin + 84,
        logoImageHeight +
          largeMargin * 3 +
          smallMargin * 15 +
          tinyMargin * 9 +
          firstParagraphMargin +
          pieChartImageHeight +
          (sqlServersLines.length > 2 ? tinyMargin : 0),
        { align: "center" }
      )
      .text(
        engagementNameFooterLines,
        leftMargin + 84,
        logoImageHeight +
          largeMargin * 3 +
          smallMargin * 17 +
          tinyMargin * 7 +
          firstParagraphMargin +
          pieChartImageHeight +
          (sqlServersLines.length > 2 ? tinyMargin : 0),
        { align: "center" }
      )
      .text(
        copyRightFooterLines,
        leftMargin + 84,
        logoImageHeight +
          largeMargin * 3 +
          smallMargin * 18 +
          tinyMargin * 7 +
          firstParagraphMargin +
          pieChartImageHeight +
          (sqlServersLines.length > 2 ? tinyMargin : 0),
        { align: "center" }
      )

    const maxFileNamelength = 128
    const fileName = `Migration Evaluator Quick Insights - ${reportDetails.getEngagementSummaryReport.EngagementName}`
    pdf.save(`${fileName.substring(0, maxFileNamelength)}.pdf`)
  }

  private printSqlTotalWithBreakDown = () => {
    const { reportDetails } = this.state
    let componentString = ""
    const breakDown = []
    const databaseCount =
      reportDetails.getEngagementSummaryReport.Data.CountByDatabase
    let totalServers = 0
    if (databaseCount.SqlStandard !== null && databaseCount.SqlStandard !== 0) {
      breakDown.push(`Standard: ${databaseCount.SqlStandard}`)
      totalServers += databaseCount.SqlStandard
    }

    if (
      databaseCount.SqlEnterprise !== null &&
      databaseCount.SqlEnterprise !== 0
    ) {
      breakDown.push(`Enterprise: ${databaseCount.SqlEnterprise}`)
      totalServers += databaseCount.SqlEnterprise
    }
    if (databaseCount.SqlWeb !== null && databaseCount.SqlWeb !== 0) {
      breakDown.push(`Web: ${databaseCount.SqlWeb}`)
      totalServers += databaseCount.SqlWeb
    }
    if (
      databaseCount.SqlDeveloper !== null &&
      databaseCount.SqlDeveloper !== 0
    ) {
      breakDown.push(`Developer: ${databaseCount.SqlDeveloper}`)
      totalServers += databaseCount.SqlDeveloper
    }
    if (databaseCount.SqlExpress !== null && databaseCount.SqlExpress !== 0) {
      breakDown.push(`Express: ${databaseCount.SqlExpress}`)
      totalServers += databaseCount.SqlExpress
    }
    if (databaseCount.Other !== null && databaseCount.Other !== 0) {
      breakDown.push(`Other: ${databaseCount.Other}`)
      totalServers += databaseCount.Other
    }
    componentString = breakDown.length > 0 ? `(${breakDown.join(", ")})` : ""
    return (
      <>
        {this.formatMessage(messages.sqlServers, {
          totalCount: totalServers.toLocaleString(),
          components: componentString
        })}
      </>
    )
  }

  private operatingSystemList = () => {
    const { reportDetails } = this.state
    let componentString = ""
    const breakDown = []
    const osCount =
      reportDetails.getEngagementSummaryReport.Data.CountByOperatingSystem
    let totalOperatingSystem = 0
    if (osCount.Linux !== null && osCount.Linux !== 0) {
      breakDown.push(`Linux: ${osCount.Linux}`)
      totalOperatingSystem += osCount.Linux
    }
    if (osCount.Windows !== null && osCount.Windows !== 0) {
      breakDown.push(`Windows: ${osCount.Windows}`)
      totalOperatingSystem += osCount.Windows
    }
    if (osCount.RHEL !== null && osCount.RHEL !== 0) {
      breakDown.push(`RHEL: ${osCount.RHEL}`)
      totalOperatingSystem += osCount.RHEL
    }
    if (osCount.SUSE !== null && osCount.SUSE !== 0) {
      breakDown.push(`SUSE: ${osCount.SUSE}`)
      totalOperatingSystem += osCount.SUSE
    }
    if (osCount.Other !== null && osCount.Other !== 0) {
      breakDown.push(`Other: ${osCount.Other}`)
      totalOperatingSystem += osCount.Other
    }
    componentString = breakDown.length > 0 ? `(${breakDown.join(", ")})` : ""
    return (
      <>
        {this.formatMessage(messages.operatingSystemServers, {
          totalCount: totalOperatingSystem.toLocaleString(),
          components: componentString
        })}
      </>
    )
  }

  private printProjectCollectionFooter = () => {
    if (this.reportVariation.IsTemporal) {
      if (this.reportVariation.HasUtilization) {
        return <>{this.formatMessage(messages.projectCollectorHintFooter)}</>
      }
      return (
        <>
          {this.formatMessage(
            messages.projectCollectorHintTimeSeriesNoUtilizationFooter
          )}
        </>
      )
    }
    return (
      <>
        {this.formatMessage(messages.projectCollectorHintNonTimeSeriesFooter)}
      </>
    )
  }

  private renderReport = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const { reportDetails } = this.state
    const options = {
      maintainAspectRatio: false,
      responsive: false,
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 10
        }
      },
      animation: {
        duration: 0
      }
    }

    const rightSizedMatchResult =
      reportDetails.getEngagementSummaryReport.Data.RightSizedMatchResult
    const totalMatchCost =
      rightSizedMatchResult.ComputeAnnualCost +
      rightSizedMatchResult.StorageAnnualCost -
      rightSizedMatchResult.SoftwareLicensesCost
    const ec2RightSizeComputeAnnualCost = Math.round(
      rightSizedMatchResult.ComputeAnnualCost -
        rightSizedMatchResult.TotalLicensesCost
    )
    const ec2CostMatchPercent = Math.round(
      (ec2RightSizeComputeAnnualCost / totalMatchCost) * 100
    )
    const ebsCostMatchPercent = Math.round(
      (rightSizedMatchResult.StorageAnnualCost / totalMatchCost) * 100
    )
    const licensesCostMatchPercent = Math.round(
      (rightSizedMatchResult.OSLicensesCost / totalMatchCost) * 100
    )

    const currentYear = new Date().getFullYear()

    return (
      <div id="sumReport" hidden={!Config.Features.RenderSummaryReportHtml}>
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
          <div>
            <Box variant="p" id="workloadCostSavings">
              {this.checkReportVariations()}
              {this.reportVariation.HasUtilization
                ? formatMessage(messages.rightSizedCostText, {
                    annualCost: `$${Math.round(
                      reportDetails.getEngagementSummaryReport.Data
                        .RightSizedMatchResult.ComputeAnnualCost +
                        reportDetails.getEngagementSummaryReport.Data
                          .RightSizedMatchResult.StorageAnnualCost -
                        (Config.Features.EnableOsCostForSummaryReport
                          ? reportDetails.getEngagementSummaryReport.Data
                              .RightSizedMatchResult.TotalLicensesCost
                          : 0)
                    ).toLocaleString()} USD`
                  })
                : ""}
            </Box>
            <Box
              variant="p"
              id="costSavingAndAwsBenefits"
              padding={{ top: "s" }}
            >
              {this.reportVariation.HasUtilization
                ? `${formatMessage(messages.rightSizedSavingText, {
                    percentSavings: Math.round(
                      100 -
                        100 *
                          ((reportDetails.getEngagementSummaryReport.Data
                            .RightSizedMatchResult.ComputeAnnualCost +
                            reportDetails.getEngagementSummaryReport.Data
                              .RightSizedMatchResult.StorageAnnualCost -
                            reportDetails.getEngagementSummaryReport.Data
                              .RightSizedMatchResult.TotalLicensesCost) /
                            (reportDetails.getEngagementSummaryReport.Data
                              .DirectMatchResult.ComputeAnnualCost +
                              reportDetails.getEngagementSummaryReport.Data
                                .DirectMatchResult.StorageAnnualCost -
                              reportDetails.getEngagementSummaryReport.Data
                                .DirectMatchResult.TotalLicensesCost))
                    )
                  })} `
                : ""}
              {formatMessage(messages.rightSizedBenefitText)}
            </Box>
            {Config.Features.EnableOsCostForSummaryReport ? (
              <Box variant="p" id="costReductionAnalysis">
                {this.reportVariation.HasUtilization
                  ? `${this.formatMessage(
                      messages.microsoftLicensingRightSizedCostText,
                      {
                        licenseAnnualCost: `$${Math.round(
                          reportDetails.getEngagementSummaryReport.Data
                            .RightSizedMatchResult.OSLicensesCost
                        ).toLocaleString()} USD`
                      }
                    )} `
                  : ""}
              </Box>
            ) : null}
            <Box variant="p" id="nextSteps">
              {this.reportVariation.HasUtilization
                ? this.formatMessage(messages.nextStepsWithUtilityText)
                : this.formatMessage(messages.nextStepsWithoutUtilityText)}
            </Box>
            <br />
            <Box variant="h4" id="aboutReportTitle">
              {formatMessage(messages.aboutReportTitle)}
            </Box>
            <Box variant="p" id="analysisTimeFrame">
              {this.checkReportVariations()}
              {this.reportVariation.IsTemporal
                ? this.formatMessage(messages.analysisTimePeriodText, {
                    startDate: SummaryReport.formatUTC(
                      reportDetails.getEngagementSummaryReport.AnalysisStartDate
                    ),
                    endDate: SummaryReport.formatUTC(
                      reportDetails.getEngagementSummaryReport.AnalysisEndDate
                    )
                  })
                : this.formatMessage(messages.analysisTimePointText)}
            </Box>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <TextContent>
                <ul>
                  <li>
                    <Box variant="p" id="servers">
                      {this.formatMessage(messages.servers)}
                    </Box>
                    <ul>
                      <li>
                        <Box id="virtualMachines">
                          {this.formatMessage(messages.virtualMachines, {
                            count:
                              reportDetails.getEngagementSummaryReport.Data
                                .CountByServerType.VirtualMachines
                          })}
                        </Box>
                      </li>
                      <li>
                        <Box id="physicalServers">
                          {this.formatMessage(messages.physicalServers, {
                            count:
                              reportDetails.getEngagementSummaryReport.Data
                                .CountByServerType.PhysicalServers
                          })}
                        </Box>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Box variant="p" id="licensing">
                      {this.formatMessage(messages.licensing)}
                    </Box>
                    <ul>
                      <li>
                        <Box id="operatingSystemServers">
                          {this.operatingSystemList()}
                        </Box>
                      </li>
                      <li>
                        <Box id="sqlServers">
                          {this.printSqlTotalWithBreakDown()}
                        </Box>
                      </li>
                    </ul>
                  </li>
                </ul>
              </TextContent>
              <TextContent>
                <ul>
                  <li>
                    <Box variant="p" id="storage">
                      {this.formatMessage(messages.storage)}
                    </Box>
                    <ul>
                      <li>
                        <Box id="attachedBlockStorage">
                          {this.formatMessage(messages.attachedBlockStorage, {
                            size:
                              reportDetails.getEngagementSummaryReport.Data
                                .StorageDetails.AttachedBlockStorage < 1000
                                ? `${Math.round(
                                    reportDetails.getEngagementSummaryReport
                                      .Data.StorageDetails.AttachedBlockStorage
                                  )} GB`
                                : `${Math.round(
                                    reportDetails.getEngagementSummaryReport
                                      .Data.StorageDetails
                                      .AttachedBlockStorage / 1000
                                  )} TB`
                          })}
                        </Box>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Box variant="p" id="utilization">
                      {this.formatMessage(messages.utilization)}
                    </Box>
                    <ul>
                      <li>
                        <Box id="peakCpuUtilization">
                          {this.reportVariation.HasUtilization
                            ? this.formatMessage(messages.peakCpuUtilization, {
                                percent: `${Math.round(
                                  reportDetails.getEngagementSummaryReport.Data
                                    .Utilization.PeakCpuUtilizationPercent * 10
                                ) / 10}%`
                              })
                            : this.formatMessage(messages.peakCpuUtilization, {
                                percent: "N/A"
                              })}
                        </Box>
                      </li>
                      <li>
                        <Box id="peakMemoryUtilization">
                          {this.reportVariation.HasUtilization
                            ? this.formatMessage(
                                messages.peakMemoryUtilization,
                                {
                                  percent: `${Math.round(
                                    reportDetails.getEngagementSummaryReport
                                      .Data.Utilization
                                      .PeakMemoryUtilizationPercent * 10
                                  ) / 10}%`
                                }
                              )
                            : this.formatMessage(
                                messages.peakMemoryUtilization,
                                {
                                  percent: "N/A"
                                }
                              )}
                        </Box>
                      </li>
                    </ul>
                  </li>
                </ul>
              </TextContent>
            </Grid>
            <Box variant="p" id="projectedCostsFooter" padding={{ top: "s" }}>
              {formatMessage(messages.projectedCostsFooter, {
                hasSoftwareAssurance:
                  reportDetails.getEngagementSummaryReport.Scenario
                    .HasSoftwareAssurance,
                purchaseType:
                  reportDetails.getEngagementSummaryReport.Scenario
                    .PurchaseType,
                region: reportDetails.getEngagementSummaryReport.Scenario.Region
              })}
            </Box>
            <Box id="projectCollectorHintFooter" variant="p">
              <i>{this.printProjectCollectionFooter()}</i>
            </Box>
            <Box variant="p">
              <i id="utilizationHintFooter">
                {formatMessage(messages.utilizationHintFooter)}
              </i>
            </Box>
            <Box id="engagementNameFooter" variant="p">
              <i>
                {formatMessage(messages.engagementNameFooter, {
                  engagementName:
                    reportDetails.getEngagementSummaryReport.EngagementName
                })}
              </i>
            </Box>
            <Box id="copyRightFooter" variant="p">
              <i>{formatMessage(messages.copyRightFooter, { currentYear })}</i>
            </Box>
          </div>
          <div id="pieChart">
            {this.reportVariation.HasUtilization ? (
              <Pie
                data={{
                  labels: [
                    `${formatMessage(
                      messages.awsEc2Label
                    )} : ${ec2CostMatchPercent}%`,
                    `${formatMessage(
                      messages.awsEbsLabel
                    )}: ${ebsCostMatchPercent}%`,
                    Config.Features.EnableOsCostForSummaryReport
                      ? `${formatMessage(
                          messages.microsoftLicensesLabel
                        )} : ${licensesCostMatchPercent}%`
                      : null
                  ].filter(Boolean),
                  datasets: [
                    {
                      label: formatMessage(messages.awsEc2Label),
                      backgroundColor: ["#7492e7", "#dfe6ff", "#2ea597"],
                      data: [
                        ec2RightSizeComputeAnnualCost,
                        reportDetails.getEngagementSummaryReport.Data
                          .RightSizedMatchResult.StorageAnnualCost,
                        Config.Features.EnableOsCostForSummaryReport
                          ? reportDetails.getEngagementSummaryReport.Data
                              .RightSizedMatchResult.OSLicensesCost
                          : null
                      ].filter(Boolean)
                    }
                  ]
                }}
                options={options}
                height={201}
                width={268}
              />
            ) : null}
          </div>
        </Grid>
      </div>
    )
  }

  private showReport = () => {
    const {
      intl: { formatMessage }
    } = this.props

    return (
      <>
        {this.renderReport()}
        {Config.Features.RenderSummaryReportHtml ? (
          ""
        ) : (
          <FormField
            id="SummaryReportDescription"
            label={
              <>
                <Box variant="p">
                  <br />
                  {formatMessage(messages.featureIntroPart1)}
                </Box>
                <Box variant="p">
                  {formatMessage(messages.featureIntroPart2)}
                </Box>
              </>
            }
          />
        )}
      </>
    )
  }

  private extractDetailedFormatFromButtonEvent = (event: CustomEvent) => {
    if (event.detail.id === StandardDetailedFormatId) {
      return DetailedFormat.Standard
    }
    if (event.detail.id === MpaDetailedFormatId) {
      return DetailedFormat.MPA
    }
    if (event.detail.id === PhysicalDetailedFormatId) {
      return DetailedFormat.PHYSICAL
    }
    console.error("Unexpected detailed export format")
    return "" // Fall back to default behaviour
  }

  private renderDetailedExportDownloadButton = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const { detailedReportId, availableDetailedFormats } = this.props
    const {
      isDownloadingDetailedReport,
      errorExist,
      canDownloadPhysicalServerReport
    } = this.state
    const dropDownList = [
      {
        text: formatMessage(messages.detailedExportStandardFormatLabel),
        id: StandardDetailedFormatId,
        iconName: "download",
        disabled: !availableDetailedFormats.includes(DetailedFormat.Standard)
      },
      {
        text: formatMessage(messages.detailedExportMpaFormatLabel),
        id: MpaDetailedFormatId,
        iconName: "download",
        disabled: !availableDetailedFormats.includes(DetailedFormat.MPA)
      }
    ]
    if (canDownloadPhysicalServerReport) {
      dropDownList.push({
        text: formatMessage(messages.detailedExportPhysicalFormatLabel),
        id: PhysicalDetailedFormatId,
        iconName: "download",
        disabled: !availableDetailedFormats.includes(DetailedFormat.PHYSICAL)
      })
    }

    return (
      <ButtonDropdown
        data-testid="downloadDetailsBtn"
        id="downloadDetailsBtn"
        items={dropDownList as ButtonDropdownProps.Items}
        onItemClick={event => {
          const format = this.extractDetailedFormatFromButtonEvent(event)
          this.downloadDetailedExport(format)
        }}
        disabled={
          !detailedReportId || errorExist || isDownloadingDetailedReport
        }
      >
        {messages.downloadDetailedReport.defaultMessage}
      </ButtonDropdown>
    )
  }

  private renderQideInfoTooltip = () => {
    return (
      <Popover
        data-testid="detailedExportTooltip"
        size="large"
        triggerType="custom"
        dismissButton={false}
        content={
          <>
            <Box variant="p">
              <FormattedMessage
                id={messages.quickInsightsDetailedStandardHint.id}
                defaultMessage={
                  messages.quickInsightsDetailedStandardHint.defaultMessage
                }
                values={{ bolded: <b>Standard</b> }}
              />
            </Box>
            <Box variant="p">
              <FormattedMessage
                id={messages.quickInsightsDetailedMpaHint.id}
                defaultMessage={
                  messages.quickInsightsDetailedMpaHint.defaultMessage
                }
                values={{
                  bolded: <b>MPA</b>
                }}
              />
            </Box>
            <Box variant="p">
              <FormattedMessage
                id={messages.quickInsightsSupportHint.id}
                defaultMessage={
                  messages.quickInsightsSupportHint.defaultMessage
                }
                values={{
                  bolded: <b>Not Generated</b>,
                  supportContact: (
                    <Link
                      id="supportEmailLink"
                      href={`mailto:${Config.Support.EmailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {Config.Support.EmailAddress}&nbsp;
                      <Icon variant="link" name="envelope" />
                    </Link>
                  )
                }}
              />
            </Box>
          </>
        }
      >
        <StatusIndicator id="detailedExportInfoIcon" type="info" />
      </Popover>
    )
  }

  private hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : { r: 0, g: 0, b: 0 }
  }

  private printNormalBoldMix = (
    pdfToBuild: JsPdf,
    markedUpString: any,
    startX: any,
    startY: any,
    generalLineSpacing: any
  ) => {
    const textMap = markedUpString
    let startXnew = startX
    let startYnew = startY
    const bold = "Bold"
    const light = "Light"
    const lineSpacing = generalLineSpacing
    let boldOpen = false
    textMap.forEach((text: any) => {
      const arrayOfNormalAndBoldText = `${text}`.split("##")
      arrayOfNormalAndBoldText.forEach((textItems: any, i: number) => {
        pdfToBuild.setFont(
          boldOpen ? AmazonFontLight : AmazonFontBold,
          boldOpen ? light : bold
        )
        // every even item is a normal font weight item
        if (i % 2 === 0) {
          pdfToBuild.setFont(
            boldOpen ? AmazonFontBold : AmazonFontLight,
            boldOpen ? bold : light
          )
        }
        pdfToBuild.text(textItems, startXnew, startYnew)
        startXnew += pdfToBuild.getTextWidth(textItems)
      })
      boldOpen = this.isBoldOpen(arrayOfNormalAndBoldText.length, boldOpen)
      startXnew = startX
      startYnew += lineSpacing
    })
  }

  isBoldOpen = (arrayLength: number, valueBefore = false) => {
    const isEven = arrayLength % 2 === 0
    const result = valueBefore !== isEven

    return result
  }

  getReportedCreatedOn = () => {
    const {
      intl: { formatDate, formatTime }
    } = this.props
    const { reportDetails, detailedReportDetails, errorExist } = this.state
    const { hasReport } = this.props
    let reportCreatedOn = ""
    if (hasReport && !errorExist) {
      reportCreatedOn = detailedReportDetails?.getEngagementDetailedExport
        ?.CreatedOn
        ? detailedReportDetails.getEngagementDetailedExport.CreatedOn
        : reportDetails.getEngagementSummaryReport.CreatedOn
    }

    return `${formatDate(reportCreatedOn)} ${formatTime(reportCreatedOn)}`
  }

  updateReportDataStateAndValidate(
    currentSummaryReportData: ISummaryReportDetails
  ) {
    const { summaryReportId } = this.props
    if (
      summaryReportId === currentSummaryReportData.getEngagementSummaryReport.Id
    ) {
      this.setState({
        reportDetails: currentSummaryReportData,
        loading: false,
        errorExist: false
      })
    }
  }

  validateDetailedReportData(
    currentDetailedReportData: IDetailedReportDetails
  ) {
    const { detailedReportId } = this.props
    if (
      detailedReportId ===
      currentDetailedReportData.getEngagementDetailedExport.ReportId
    ) {
      this.setState({
        loading: false,
        errorExist: false
      })
    }
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props
    const { reportDetails, loading, errorExist } = this.state
    const { hasReport } = this.props

    if (loading) {
      return (
        <Container
          id="summaryReportContainer"
          header={
            <Header variant="h2">
              {formatMessage(messages.header, { engagementName: "" })}
            </Header>
          }
        >
          <div className="awsui text-center">
            <span className="v-center">
              <Spinner size="big" variant="disabled" />
            </span>
          </div>
        </Container>
      )
    }

    return (
      <Container
        header={
          <Header variant="h2" data-testid="quickInsightsHeader">
            {formatMessage(messages.header, {
              engagementName: reportDetails.getEngagementSummaryReport
                .EngagementName
                ? reportDetails.getEngagementSummaryReport.EngagementName
                : ""
            })}
          </Header>
        }
        footer={
          <Box data-testid="featureFooter1">
            <i>{formatMessage(messages.featureFooter1)}</i>
          </Box>
        }
      >
        <SpaceBetween direction="vertical" size="xl">
          <div>
            <div>
              <Alert
                id="serverError"
                header={formatMessage(common.errorHeader)}
                type="error"
                dismissible
                visible={errorExist}
                onDismiss={() => this.setState({ errorExist: false })}
                dismissAriaLabel="Close alert"
              >
                {formatMessage(common.error)}
              </Alert>
            </div>
            <div>
              <img
                id="migrationEvaluatorLogo"
                hidden
                src={migrationEvaluatorLogo}
                alt="logo"
              />
            </div>
            {this.showReport()}
          </div>
          <SpaceBetween direction="horizontal" size="s">
            <Button
              data-testid="downloadPdf"
              variant="primary"
              iconName="download"
              onClick={this.downloadPdf}
              disabled={!hasReport || errorExist}
            >
              {formatMessage(messages.downloadPdf)}
            </Button>
            {this.renderDetailedExportDownloadButton()}
            {this.renderQideInfoTooltip()}
          </SpaceBetween>
          <Box data-testid="lastUpdateHint">
            {hasReport && !errorExist
              ? formatMessage(messages.lastUpdateHint, {
                  date: this.getReportedCreatedOn()
                })
              : formatMessage(messages.noReportHint)}
          </Box>
        </SpaceBetween>
      </Container>
    )
  }
}

export default injectIntl(withRouter(SummaryReport))
